import * as moment from 'moment-timezone';
import { DurationInputArg2, unitOfTime } from 'moment';
import { DateTimeFormat } from '../modules/date';

export class DateUtils {
  // Comparisons

  static unixAfterDaysAgo(ts: number, days: number): boolean {
    return DateUtils.currentTimestamp() - days * DateUtils.unixOneDay() < ts;
  }

  static unixAfterHoursAgo(ts: number, hours: number): boolean {
    return DateUtils.currentTimestamp() - hours * DateUtils.unixOneHour() < ts;
  }

  static unixAfterMinutesAgo(ts: number, minutes: number): boolean {
    return DateUtils.currentTimestamp() - minutes * 60 < ts;
  }

  // Unix Helpers

  static currentTimestamp(): number {
    return Math.round(new Date().getTime() / 1000);
  }

  static unixOneMonth(): number {
    return 30 * 24 * 60 * 60;
  }

  static unixOneWeek(): number {
    return 7 * 24 * 60 * 60;
  }

  static unixOneDay(): number {
    return 24 * 60 * 60;
  }

  static unixOneHour(): number {
    return 60 * 60;
  }

  // Convert Time

  static convertUnixTimeToLocalTimeZone(date: number): any {
    const utcMoment = moment.unix(date);
    return moment(utcMoment).local();
  }

  static convertTimeToLocalTimeZone(date: Date, local?: boolean): any {
    date = DateUtils.validateDate(date);
    const utcMoment = moment.utc(date);
    return moment(utcMoment).local(local);
  }

  static convertTimeToSpecifiedTimeZone(date: Date, timezone: string): any {
    date = DateUtils.validateDate(date);
    const utcMoment = moment.utc(date);
    if (timezone === null) {
      return moment(utcMoment).local();
    } else {
      return moment(utcMoment).tz(timezone);
    }
  }

  // Format Date & Time

  static formatDateAndTime(date, format: DateTimeFormat) {
    return moment(date).format(format);
  }

  static formatDateToAtString(
    date: Date,
    local?: boolean,
    timezone?: string
  ): string {
    date = DateUtils.validateDate(date);
    if (timezone === null || local === true) {
      const convertedTime = moment(date).local(local);
      return [
        convertedTime.format('MMM D'),
        'at',
        convertedTime.format('h:mm a'),
      ]
        .join(' ')
        .trim();
    } else {
      const convertedTime = moment(date).tz(timezone);
      return [
        convertedTime.format('MMM D'),
        'at',
        convertedTime.format('h:mm a'),
      ]
        .join(' ')
        .trim();
    }
  }

  static formatTimeRangeToReadableString(
    date1: Date,
    date2: Date,
    timezone?: string
  ): string {
    const moment1 = moment.utc(date1);
    const moment2 = moment.utc(date2);
    if (!!date1 && !!date2 && timezone) {
      return `${moment(moment1).tz(timezone).format('h:mm a')} - ${moment(
        moment2
      )
        .tz(timezone)
        .format('h:mm a')}`;
    } else if (!!date1 && timezone) {
      return moment(moment1).tz(timezone).format('h:mm a');
    }
    if (!!date1 && !!date2) {
      return `${moment(moment1).local().format('h:mm a')} - ${moment(moment2)
        .local()
        .format('h:mm a')}`;
    } else if (!!date1) {
      return moment(moment1).local().format('h:mm a');
    }
    return '';
  }

  static formatDeliveryDateToReadabletring(date: Date,timezone?: string): string {
    const moment1 = moment.utc(date);
    var deliverBefore = '';
    if (!!date)
      deliverBefore = moment(moment1).tz(timezone).format('h:mm a');

    return deliverBefore
  }

  static dateIsBefore(one, two: Date): number {
    one = DateUtils.validateDate(one);
    two = DateUtils.validateDate(two);
    return moment(one).isBefore(moment(two))
      ? -1
      : moment(one).isAfter(moment(two))
      ? 1
      : 0;
  }

  static dateRangeContains(
    rangeStart: Date,
    rangeEnd: Date,
    containsDate: Date
  ): boolean {
    return rangeStart <= containsDate && containsDate <= rangeEnd;
  }

  static dateRangeOverlaps(
    aStart: Date,
    aEnd: Date,
    bStart: Date,
    bEnd: Date
  ): boolean {
    if (aStart <= bStart && bStart <= aEnd) {
      return true;
    } // b starts in a
    if (aStart <= bEnd && bEnd <= aEnd) {
      return true;
    } // b ends in a
    if (bStart < aStart && aEnd < bEnd) {
      return true;
    } // a in b
    return false;
  }

  static validateDate(d): Date {
    if (d instanceof Date) {
      return d;
    } else {
      return new Date(d);
    }
  }

  static ngbDateIsBeforeToday(year, month, day: number): boolean {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // ngb
    const currentDay = currentDate.getDate();
    const pastYear = currentYear > year;
    const pastMonth = currentYear === year && currentMonth > month;
    const pastDay =
      currentYear === year && currentMonth === month && currentDay > day;
    return pastYear || pastMonth || pastDay;
  }

  static endOfDay(date: Date): Date {
    const d = new Date(date);
    d.setHours(23, 59, 59, 999);
    return d;
  }

  static startOfDay(date: Date): Date {
    const d = new Date(date);
    d.setHours(0, 0, 0, 0);
    return d;
  }

  static startOf(date?: Date, unit?: unitOfTime.StartOf): Date {
    const startDate = !!date ? moment(date) : moment();
    return startDate.startOf(unit).toDate();
  }

  static endOf(date?: Date, unit?: unitOfTime.StartOf): Date {
    const endDate = !!date ? moment(date) : moment();
    return endDate.endOf(unit).toDate();
  }

  static addTime(date: Date, amount: number, unit?: DurationInputArg2): Date {
    return moment(date).add(amount, unit).toDate();
  }

  static subtractTime(
    date: Date,
    amount: number,
    unit: DurationInputArg2
  ): Date {
    return moment(date).subtract(amount, unit).toDate();
  }

  // returns time, ex: 11:32 AM, using system Time Zone,
  static getTime(date: Date): string {
    const dateFromTimeZone = new Date(
      date.getTime() + date.getTimezoneOffset() * 600 * 1000 * 2
    );
    return dateFromTimeZone.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    });
  }

  static convert24HourTo12HourTimeString(timeString: string) {
    if (!timeString) {
      return '';
    }
    const H = +timeString.substr(0, 2);
    const h = H % 12 || 12;
    const ampm = H < 12 || H === 24 ? $localize`am` : $localize`pm`;
    return h + timeString.substr(2, 3) + ampm;
  }

  static getTimeMinutes(time: string): number {
    return moment.duration(time).minutes();
  }

  static getMinuteIntervals(
    startTime: Date,
    endTime: Date,
    intervalMinutes: number
  ): Date[] {
    const timeStops = [];
    const startMoment = moment(startTime).startOf('minute');
    if (startMoment.minute() % intervalMinutes) {
      startMoment.add(
        intervalMinutes - (startMoment.minute() % intervalMinutes),
        'minutes'
      );
    }
    while (startMoment.toDate() <= endTime) {
      timeStops.push(moment(startMoment).toDate());
      startMoment.add(intervalMinutes, 'minutes');
    }
    return timeStops;
  }

  static isTimeSameOrBefore(time1, time2) {
    const t1 = new Date();
    let parts = time1.split(':');
    t1.setHours(parts[0], parts[1], parts.length < 3 ? null : parts[2], 0);
    const t2 = new Date();
    parts = time2.split(':');
    t2.setHours(parts[0], parts[1], parts.length < 3 ? null : parts[2], 0);

    return moment(t1).isSameOrBefore(moment(t2));
  }

  static dateInputStringToDate(
    dateString: string,
    keepLocalTime: boolean = true
  ): Date {
    const utcMoment = moment.utc(new Date(dateString));
    return moment(utcMoment).local(keepLocalTime).toDate();
  }
  static isFutureDay(dateString: string): boolean {
    return moment(dateString).isAfter(moment(), 'day');
  }
  static sortByDateDesc(a, b) {
    return moment(b).unix() - moment(a).unix();
  }
  static getTDateFormat(date: string, time: string): any {
    return `${date}T${time}`;
  }
  static getCurrentDate(): Date {
    return moment().toDate();
  }
  static getUtcDateOnly(): Date {
    return new Date(moment().utc().format('YYYY/MM/DD'));
  }

  static difference(a, b, unitOfTime: unitOfTime.Diff) {
    return moment(a).diff(moment(b), unitOfTime);
  }

  static DateTOString(date_Object: Date): string {
    let date_String: string =
      date_Object.getFullYear() +
      "/" +
      (date_Object.getMonth() + 1) +
      "/" +
      +date_Object.getDate();
    return date_String;
  }
}
